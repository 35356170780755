<template>
	<div id="Recharge">
		<div class="popup" v-if="popUp=='1'">
			<div class="inpop">
				<div class="position-absolute close" style="right: 20px; top: 25px;" @click="popUp='2'"><div><i class="el-icon-circle-close" style="font-size:30px"></i></div></div>
				<div class="text-white p-2 goCenter" style="line-height: 2;"> 请确保您本人已满18周岁，并尽快进行实名认证，认证成功赠送U币，认证中遇到问题请联系专属客服咨询。 <a href="/Me"> 去认证 <img src="../../assets/images/index/go.webp"></a></div>
				
			</div>
		</div>
		<van-tabs v-model="active" type="card">
			<van-tab title="支付宝">
				<div class="content_box">
					<div class="Recharge_list">
						<div class="Recharge_item" v-for="(item,index) in BeanList" :key="index" @click="ToRecharge(item.id,'8')">
							<div class="Smbox">
								<p><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</p>
								<span>￥{{item.price}}</span>
							</div>
						</div>
					</div>
				</div>
			</van-tab>
			<van-tab title="微信">
				<div class="content_box">
					<div class="Recharge_list">
						<div class="Recharge_item" v-for="(item,index) in BeanList" :key="index" @click="ToRecharge(item.id,'7')">
							<div class="Smbox">
								<p><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</p>
								<span>￥{{item.price}}</span>
							</div>
						</div>
					</div>
				</div>
			</van-tab>
				<!-- <div class="content_box">
					<h1 style="color:#fff;font-size:15px;padding:10px" >卡密兑换</h1>
					<van-field v-model="CardCode" center clearable label="卡密">
						<template #button>
							<van-button class="vbtn" size="small" type="info" @click="PustCardRecharge" style="color:#fff !important">兑换</van-button>
						</template>
					</van-field>
				
				</div> -->
				<div class="content_box">
					<h1 style="color:#fff;font-size:15px;padding:10px" >充值记录</h1>
					<div class="record_box">
						<div class="record_item">
							<p>金豆</p>
							<p>价格</p>
							<p>状态</p>
						</div>
						<div class="record_item" v-for="(item,index) in RecordList" :key="index">
							<p>{{item.bean}}</p>
							<p>{{item.price}}</p>
							<p>{{item.status_alias}}</p>
						</div>
					</div>
					<el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" @current-change="handleCurrentChange" :pager-count='5' prev-text="上一页" next-text="下一页" small>
					</el-pagination>
				</div>
		</van-tabs>
	</div>
</template>

<script>
import {
		mapState,
	} from 'vuex'
	import {
		Beans,
		RechargeRecord,
		CardRecharge
	} from '@/network/api.js'
	export default {
		name: 'Recharge',
		data() {
			return {
				active: 0, //tab分栏
				BeanList: [], //金豆列表
				pay_way: '1', //支付类别,1支付宝,2微信
				CardCode:'',//卡密兑换码
				RecordList: [], //充值记录

				total:0,//总数据数量
				per_page:0,//一页数据
				page_size:1,//页码
				popUp:'2',
			}
		},
		created() {
			this.GetBeans()
			this.GetRechargeRecord()
		},
		methods: {
			//金豆列表
			GetBeans() {
				Beans().then((res) => {
					// console.log(res.data.data)
					this.BeanList = res.data.data
				})
			},
			// 支付(携带参数跳转页面，防止拦截)
			ToRecharge(id,type) {
				// if(this.pay_way == '1') this.pay_way = 3
				if(this.User.fullname!=null){
					let routeUrl = this.$router.resolve({
						path: "/Payment",
						query: {
							id: id,
							// pay_way: this.pay_way,
							type:type
						}
					});
					window.open(routeUrl.href, '_self')
				}else{
					this.popUp='1'
				}
				
			},

			//卡密购买
			ToCard(v){
				// console.log(v)
				window.open(v,'_self')
			},

			PustCardRecharge(){
				CardRecharge(this.CardCode).then((res) => {
					// console.log(res)
					this.$notify({
						type: 'success',
						message: res.data.message
					})
				})
			},

			//充值记录
			GetRechargeRecord() {
				RechargeRecord(this.page_size).then((res) => {
					// console.log(res.data.data)
					this.total = res.data.data.total
					this.per_page = res.data.data.per_page
					this.RecordList = res.data.data.data
				})
			},
			//充值记录分页
			handleCurrentChange(val) {
				// console.log(val)
				this.page_size = val
				this.GetRechargeRecord()
			}
		},
		computed: {
			...mapState([
				'User'
			])
		},
		watch: {
			active(v) {
				// console.log(v)
				this.pay_way = v + 1
				// console.log(this.pay_way)
			}
		}

	}
</script>

<style lang="scss">
.popup{
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.5);
  width: 100%;
  height: 100%;
  z-index: 99;
  .close{
	position: absolute;
  }
  .inpop{
	color: white;
    // left: calc(50% - 40%);
	width: 60%;
	margin: 0 auto;
    top: 30vh;
    position: relative;
    background-color: #13141c!important;
    border-radius: 5px!important;
    border: 1px solid #393d5b;
    box-shadow: 0 17px 57px 0 rgba(0,0,0,.77);
    // display: flex;
    justify-content: center;
    padding: 40px;
    // @media (max-width: 1000px) {
    //   width: 80%;
    // }
    >div{
      display: flex;
      align-items: center;
      @media (max-width: 1000px) {
        flex-wrap: wrap;
      }
      a{
        display: flex;
        align-items: center;
        color: #e5b375!important;
        text-decoration: underline!important;
        cursor: pointer;
      }
    }
  }
}
	#Recharge {
		width: 100%;
		padding: 10px 0;
		box-sizing: border-box;
		background: url('../../assets/images/q2/recharge/bac.png') no-repeat center;
		background-size: 100% 100%;
		.van-tabs {
			.van-tabs__wrap {
				height: 50px;
				.van-tabs__nav--card {
					border: none !important;

					&.van-tabs__nav {
						background: none !important;
					}

					.van-tab {
						margin: 0 2px;
						// background: #77b300 !important;
						color: #fff !important;
						border-right: 1px solid #4854c9 !important;
						border-right: none !important;
						border-radius: 2px 2px 0 0;
						padding-top: 10px !important;
						padding-bottom: 10px !important;
						height: 50px !important;
						// background: url('../../assets/images/q2/recharge/wei.png') no-repeat center !important;
						// background-size: 100% 100% !important;
						background: #0000006a;
						border-radius: 5px;
						&:first-child {	
							margin-left: 0;
						}

						&:last-child {
							margin-right: 0;
						}

						&.van-tab--active {
							background: url('../../assets/images/q2/recharge/zhi.png') no-repeat center !important;
							background-size: 100% 100% !important;
						}
					}
				}
			}
		}
		.content_box:nth-child(2){
			min-height: 50px;
			background: #000;
			margin-top: 20px;
			margin-bottom: 20px;
			background: url('../../assets/images/q2/recharge/bottombac.png') no-repeat center;
			background-size: 100% 100%;
		}
		.content_box:nth-child(3){
			background: url('../../assets/images/q2/recharge/bottombac.png') no-repeat center;
			background-size: 100% 100%;
		}
		.content_box {
			width: 91%;
			min-height: 100px;
			margin: 0 auto;
			
			// background: #20222b;
			background-size: cover;
			// padding: 10px;
			box-sizing: border-box;
			.vbtn{
				background: url('../../assets/images/q2/recharge/btn.png') no-repeat center !important;
				background-size: 100% 100% !important;
				color: white !important;
				padding: 20px;
			}
			.notice {
				text-align: center;
				font-size: 14px;
				color: #fff;
				margin: 5px 0;
			}
			
			.Recharge_list {
				width: 100%;
				// padding: 10px;
				box-sizing: border-box;
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
				justify-content: flex-start;
				.Recharge_item {
					width: 33%;
					height: 120px;
					// background: rgba($color: #191e2b, $alpha: .4);
					// border: 2px solid #ffa217;
					box-sizing: border-box;
					margin-bottom: 18px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: url('../../assets/images/985/recharge/rechargebac.png') !important;
					background-size: 100% 100% !important;
					/*border-radius: 4px;*/

					.Smbox {
						display: inline-block;
						text-align: center;

						p {
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 14px;
							color: #f1bc60;
							margin-bottom: 5px;

							img {
								width: 15px;
								vertical-align: middle;
							}
						}

						span {
							color: #ccc;
							font-size: 10px;
						}
					}
				}
			}

			.record_box {
				width: 100%;
				margin-top: 5px;
				margin-bottom: 20px;
				min-height: 320px;
				.record_item {
					width: 100%;
					height: 50px;
					display: flex;
					line-height: 50px;
					background: rgba(0,0,0,.5);
					p {
						flex: 1;
						padding-left: 20px;
						box-sizing: border-box;
						color: #fff;
						font-size: 14px;
					}

					&:nth-child(2n) {
						background: linear-gradient(90deg, rgba(255, 162, 24, 0), rgba(255, 162, 24, .19) 52%, rgba(255, 162, 24, 0));
					}

					&:first-child {
						p {
							font-weight: bold;
						}
					}
				}
			}

			.van-cell {
				background: none;
				margin-top: 10px;

				.van-field__label {
					width: 32px;
					font-size: 16px;
					color: #fff;
				}

				.van-field__control {
					color: #fff;
				}

				.van-button {
					margin-top: 0;
				}
			}
			.van-cell::after {
				background: transparent;
			}
		}

	}
</style>
